<template>
  <div class="checkout d-flex justify-center">
    <div class="qr-scan text-center mx-auto">
      <h1 class="main-color">{{ $t("Scan QR") }}</h1>
      <QRcodeScanner
        @onSuccess="onScan"
        style="width: 350px; max-width: 100%"
      />
    </div>
    <div class="form-card text-center">
      <v-form ref="form" v-model="valid" @submit.prevent="check">
        <v-text-field
          :placeholder="$t('Enter Card Number')"
          :rules="[validationRules.required]"
          v-model.trim="uuid"
          outlined
        ></v-text-field>
        <p class="error--text" v-if="validationMsg">{{ validationMsg }}</p>
        <div class="actions text-center">
          <v-btn class="submit-btn main-btn" @click="check">{{
            $t("Nedaa Check")
          }}</v-btn>
        </div>
      </v-form>
    </div>

    <div class="success-card text-center">
      <p class="main-color">{{ studentName }}</p>
      <p class="success--text">
        <v-icon color="#4caf50">check</v-icon> {{ $t("Has been called") }}
      </p>
      <img src="@/assets/child.png" alt="" />
      <div class="actions text-center">
        <v-btn class="submit-btn main-btn" @click="Checkout">{{
          $t("Nedaa Checkout")
        }}</v-btn>
      </div>
    </div>

    <div class="notyet-card text-center">
      <p class="student-name main-color">{{ studentName }}</p>
      <p class="error--text">{{ $t("Not yet!") }}</p>
    </div>

    <div class="left-gate" :style="{ left: tweenedNumber }">
      <img src="../../assets/left-gate.png" alt="" />
    </div>
    <div class="right-gate" :style="{ right: tweenedNumber }">
      <img src="../../assets/right-gate.png" alt="" />
    </div>
    <div class="left-gate-red" :style="{ left: tweenedNumberRedGate }">
      <img src="../../assets/left-gate-red.png" alt="" />
    </div>
    <div class="right-gate-red" :style="{ right: tweenedNumberRedGate }">
      <img src="../../assets/right-gate-red.png" alt="" />
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { validationMixin } from "@/mixins/validationMixin";
import QRcodeScanner from "@/components/QRcodeScanner.vue";

export default {
  name: "Checkout",
  mixins: [validationMixin],

  components: {
    QRcodeScanner,
  },
  data() {
    return {
      valid: false,
      sumbitted: false,
      gateOpen: false,
      openGate: 0,
      closeGate: "-25%",
      tweenedNumber: 0,
      tweenedNumberRedGate: "-25%",
      uuid: "",
      validationMsg: "",
      statusMsg: "",
      studentName: "",
      canCheckout: false,
    };
  },
  watch: {
    openGate(newValue) {
      gsap.to(this.$data, {
        duration: 2,
        ease: "ease",
        tweenedNumber: newValue,
      });
    },

    uuid() {
      this.validationMsg = "";
    },
  },
  methods: {
    onScan(decodedText) {
      this.uuid = decodedText;
      this.valid = true;
      this.check();
    },
    MakeGateClosed() {
      gsap.to(".qr-scan", {
        duration: 0.5,
        display: "none",
        opacity: 0,
      });
      gsap.to(".form-card", {
        duration: 0.5,
        display: "none",
        opacity: 0,
      });
      gsap.to(".notyet-card", {
        duration: 0.5,
        display: "block",
        opacity: 1,
      });
      gsap.to(".left-gate", {
        duration: 0.5,
        display: "none",
        opacity: 0,
      });
      gsap.to(".right-gate", {
        duration: 0.5,
        display: "none",
        opacity: 0,
      });
      gsap.to(".left-gate-red", {
        duration: 0.5,
        display: "block",
        opacity: 1,
      });
      gsap.to(".right-gate-red", {
        duration: 0.5,
        display: "block",
        opacity: 1,
      });
      gsap.to(".right-gate-red", {
        duration: 2,
        right: 0,
      });
      gsap.to(".left-gate-red", {
        duration: 2,
        left: 0,
      });

      this.reset();
    },

    reset() {
      setTimeout(() => {
        gsap.to(".qr-scan", {
          duration: 0.1,
          display: "block",
          opacity: 1,
        });
        gsap.to(".form-card", {
          duration: 0.1,
          display: "block",
          opacity: 1,
        });
        gsap.to(".notyet-card", {
          duration: 0.1,
          display: "none",
          opacity: 0,
        });

        gsap.to(".left-gate", {
          duration: 0.1,
          display: "block",
          opacity: 1,
        });
        gsap.to(".right-gate", {
          duration: 0.1,
          display: "block",
          opacity: 1,
        });

        gsap.to(".left-gate-red", {
          duration: 0.1,
          display: "none",
          opacity: 0,
        });
        gsap.to(".right-gate-red", {
          duration: 0.1,
          display: "none",
          opacity: 0,
        });
        gsap.to(".right-gate-red", {
          duration: 1,
          right: "-25%",
        });
        gsap.to(".left-gate-red", {
          duration: 1,
          left: "-25%",
        });
        this.resetData();
      }, 4000);
    },
    resetData() {
      this.canCheckout = false;
      this.uuid = "";
      this.studentName = "";
      this.statusMsg = "";
      this.resetValidation();
    },
    check() {
      if (this.valid) {
        console.log("called");
        this.canCheckout = false;
        this.$http
          .get(this.getApiUrl + "/nedaa/checkStudentCall/" + this.uuid, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.statusMsg = response.data.status.message;
            if (this.statusMsg == "success" || this.statusMsg == "نجاح") {
              this.studentName = response.data.data.student;
              this.canCheckout = true;
              let tl = gsap.timeline();
              tl.to(".qr-scan", {
                duration: 1,
                display: "none",
                opacity: 0,
                x: 10,
              });
              tl.to(".form-card", {
                duration: 1,
                display: "none",
                opacity: 0,
                x: 10,
              });
              tl.to(".success-card", {
                duration: 1,
                display: "block",
                opacity: 1,
                x: "-10px",
              });
              setTimeout(() => {
                this.sumbitted = true;
              }, 2000);
            } else if (
              this.statusMsg == "Not Yet." ||
              this.statusMsg == "ليس بعد"
            ) {
              this.studentName = response.data.data.student;
              this.MakeGateClosed();
            } else {
              this.validationMsg = response.data.status.message;
            }
          });
      } else {
        this.validate();
      }
    },
    Checkout() {
      this.$http
        .get(this.getApiUrl + "/nedaa/checkOutStudent/" + this.uuid, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            let tl = gsap.timeline();
            tl.to(".success-card", {
              duration: 1,
              display: "none",
              opacity: 0,
              x: 10,
            });
            tl.to(".form-card", {
              duration: 1,
              display: "block",
              opacity: 1,
              x: "-10px",
            });
            gsap.to(".qr-scan", {
              duration: 1,
              display: "block",
              opacity: 1,
              x: "-10px",
            });
            this.resetData();
            setTimeout(() => {
              this.sumbitted = false;
            }, 2000);
          }
        });
    },
  },
  mounted() {
    this.openGate = "-25%";

    gsap.from(".form-card", {
      duration: 1,
      opacity: 0,
      scale: 0,
      y: 200,
      ease: "power1",
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.checkout {
  min-height: 100vh;
  position: relative;

  .qr-scan {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 2rem 1rem;
    height: 400px;
    position: absolute;
    top: 10%;
    max-width: 100%;
  }
  .form-card,
  .success-card {
    width: 350px;
    border: 1px solid #efefef;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem 1rem;
    box-shadow: $box-shadow;
    position: absolute;
    bottom: 25%;
    .actions {
      .main-btn {
        width: 100%;
        margin: 0 auto;
        margin-right: 0 !important;
      }
    }
  }

  .notyet-card {
    width: 350px;
    border: 1px solid #ff785a;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem 1rem;
    box-shadow: $box-shadow;
    top: 15%;
    position: absolute;
    display: none;
    opacity: 0;

    .error--text {
      font-size: 1.5rem;
    }
  }

  .success-card {
    display: none;
    opacity: 0;
  }

  .left-gate {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 50vw;
  }
  .right-gate {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 50vw;
  }
  .left-gate img,
  .right-gate img,
  .right-gate-red img,
  .left-gate-red img {
    width: 100%;
    max-height: 60vh;
  }

  .left-gate-red,
  .right-gate-red {
    display: none;
    position: fixed;
    opacity: 0;
    width: 50vw;
    bottom: 0;
  }

  .left-gate-red {
    left: -25%;
  }
  .right-gate-red {
    right: -25%;
  }
}

@media (max-width: 920px) and (min-width: 600px) {
  .checkout {
    min-height: 93vh;

    .form-card,
    .success-card {
      bottom: 15%;
    }

    .left-gate img,
    .right-gate img,
    .left-gate-red img,
    .right-gate-red img {
      width: 100%;
      max-height: 60vh;
      min-height: 36vh !important;
    }
  }
}
@media (max-width: 600px) {
  .checkout {
    .qr-scan {
      top: 5%;
      height: 300px;
    }
    .form-card,
    .success-card {
      bottom: 25% !important;
      width: 300px;
    }
  }
}


</style>
